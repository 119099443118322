

















import Vue from "vue";
import { get_color_by_name } from "@/utils/colors";
export default Vue.extend({
  name: "AuthNotFound",
  methods: {
    transform_auth_icon(icon: HTMLElement): HTMLElement {
      const color = get_color_by_name("danger");
      icon.children[0].setAttribute("fill", color);
      icon.children[1].setAttribute("fill", get_color_by_name("white"));
      icon.children[2].setAttribute("fill", get_color_by_name("white"));
      return icon;
    }
  }
});
